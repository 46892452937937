import React, { useState, useEffect, useRef, useCallback } from 'react';
import LayoutWrapper from '@iso/components/utility/layoutWrapper.js';
import PageHeader from '@iso/components/utility/pageHeader';
import IntlMessages from '@iso/components/utility/intlMessages';
import PropertyMapPage from './PropertyMap.styles';
import { Map, TileLayer, FeatureGroup, Polygon, Tooltip, ZoomControl, LayersControl } from 'react-leaflet';
import { PlusOutlined, PushpinFilled, SettingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { propertyService } from '@iso/services';
// import type { RadioChangeEvent } from 'antd';
import { Button, Dropdown, Menu, Checkbox, Radio, Space, Modal, DatePicker, Row, Col, Form, Select, message } from 'antd';
import { EditControl } from "react-leaflet-draw";
import PrimaryObjectModal from './components/PrimaryObject/PrimaryObjectModal';
import _ from 'lodash';
import { objectColors } from '@iso/constants/objectColors';
import { taskService, mobService, primaryObjectService, assetService, layerService } from '@iso/services';
import { v4 as uuidv4 } from 'uuid';
import Task from './components/Task/Task';
import Mob from './components/Mob/Mob';
import Asset from './components/Asset/Asset';
import NewLiveStockModal from './components/LiveStock/NewLiveStockModal';
import BtnSaveGroup from './components/BtnSaveGroup/BtnSaveGroup';
import btnSaveAction from '@iso/redux/btnSave/actions';
import modalActions from '@iso/redux/modal/actions';
import Control from '@skyeer/react-leaflet-custom-control'
import AppLocale from '@iso/config/translation';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { store } from '@iso/redux/store';
import { DownOutlined } from '@ant-design/icons';
import L from 'leaflet';
import SelectPropertyModal from '@iso/containers/Pages/Property/SelectPropertyModal';
import { detect } from 'detect-browser';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import AreaSummaryModal from '@iso/containers/Pages/PropertyMap/components/PrimaryObject/AreaSummaryModal';
import AddLayerModal from './components/Layer/AddLayerModal';
import DrawTool from './components/Layer/DrawTool';
import { dateHelper } from '@iso/lib/helpers/dateHelper';
import { commonHelper } from '@iso/lib/helpers/commonHelper';
import HeatBar from '@iso/components/utility/heatBar';
import { iotDevice } from '@iso/services';
import DeviceMapMarker from '@iso/containers/Pages/SafeFarm/DeviceMapMarker';
import Observation from './components/Observation/Observation';

import inside from 'point-in-polygon';
import * as turf from '@turf/turf';
import { Marker, Popup } from 'react-leaflet';
import AssetFilterModal from './components/Asset/AssetFilterModal';
import PrimaryObjectFilterModal from './components/PrimaryObject/PrimaryObjectFilterModal';
import { assetCategories } from '@iso/constants/assetCategories';
import useWindowSize from "@iso/lib/hooks/useWindowSize";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

var geodesy = require('leaflet-geodesy');
const { show } = btnSaveAction;
const { openModal } = modalActions;
const { Option } = Select;

const PropertyMap = () => {
  const activePropertyId = useSelector((state) => state.property.activePropertyId);
  const { collapsed } = useSelector((state) => state.App);
  const activeProperty = localStorage. getItem('active_property');
  const activePermission = useSelector((state) => state.permission.activePermission);
  const activePropertySubscription = useSelector((state) => state.subscription.activePropertySubscription);
  const activePropertyTrialSatelliteExpired = useSelector((state) => state.property_trial_satellite.activePropertyTrialSatelliteExpired);
  const mapRef = useRef(null);
  const [propertyId, setPropertyId] = useState(null);
  const btnVisibility = useSelector((state) => state.btnSave.btnVisibility);
  const saveSuccess = useSelector((state) => state.btnSave.saveSuccess);
  const cancelSave = useSelector((state) => state.btnSave.cancelSave);
  const dataTransfer = useSelector((state) => state.btnSave.data);
  const [position, setPosition] = useState([null, null]);
  const [currentPos, setCurrentPos] = useState({});
  const controlDraw = React.useRef(null);
  const controlDrawMarker = React.useRef(null);
  const objectRef = React.useRef([]);
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const [editableFG, setEditableFG] = useState(null);
  const [currentLayer, setCurrentLayer] = useState(null);
  const [currentObject, setCurrentObject] = useState(null);
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.modal.modalData);
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const currentAppLocale = AppLocale[locale];
  const [visibleShowOption, setVisibleShowOption] = useState(false);
  const [visibleSelectProperty, setVisiblitySelectProperty] = React.useState(false);
  const [dataObject, setDataObject] = useState({});
  const [visibleSummary, setVisibleSummary] = useState(false);
  const [zoomDefault, setZoomDefault] = useState(16);
  const [disabledArea, setDisabledArea] = useState(false);
  const [darkTheme, setDarkTheme] = useState(false);
  const [trackTrace, setTrackTrace] = useState(false);
  const [propertyPIC, setPropertyPIC] = useState("");
  const [propertyAcreageUnit, setPropertyAcreageUnit] = useState("");
  const [layerChosen, setLayerChosen] = useState(0);
  const [listLayer, setListLayer] = useState([]);
  const [isEditingLayer, setIsEditingLayer] = useState(false);
  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [positionDevice, setPositionDevice] = useState();
  const [showMarkerAlert, setShowMarkerAlert] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [actionName, setActionName] = useState(null);
  const { BaseLayer, Overlay } = LayersControl;
  const [visiblePrimaryObjectFilterModal, setVisiblePrimaryObjectFilterModal] = useState(false);
  const [visibleAssetFilterModal, setVisibleAssetFilterModal] = useState(false);
  const [selectedPrimaryObjectTypeIds, setSelectedPrimaryObjectTypeIds] = useState();
  const [notSelectedPrimaryObjectTypeIds, setNotSelectedPrimaryObjectTypeIds] = useState([]);
  const [selectedAssetCategories, setSelectedAssetCategories] = useState([]);
  const [notSelectedAssetCategories, setNotSelectedAssetCategories] = useState([]);
  const [isShowAreaLabel, setIsShowAreaLabel] = useState(true);
  const [reRenderMap, setReRenderMap] = useState(1)
  const [mapKey, setMapKey] = useState(0);
  
  useEffect(() => {
    const assetCategoryValues = assetCategories.map((assetCategory) => {
      return assetCategory.value
    })
    setSelectedAssetCategories(assetCategoryValues)
  }, [])

  useEffect(() => {
    const assetCategoryValues = assetCategories.map((assetCategory) => {
      return assetCategory.value
    })
    const notSelectedCategory = assetCategoryValues.filter((category) => {
      return selectedAssetCategories.indexOf(category) === -1
    })

    setNotSelectedAssetCategories(notSelectedCategory)
  }, [selectedAssetCategories])

  const cancelAssetFilterModal = () => {
    setVisibleAssetFilterModal(false)
  }
  const updatePropertyDetail = React.useCallback((id) => {
    propertyService.viewProperty(id).then(res => {
      if (res.code === '0000') {
        setPosition([res.property.lat, res.property.lng]);
        setPrimaryObjects(res.primary_objects);
        setPropertyPIC(res.property.PIC);
        setPropertyAcreageUnit(res.property.acreage_unit);
      }
    })
  }, [collapsed]);
  useEffect(() => {
    propertyService.getProperties().then(res => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId, activePropertySubscription]);

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, [])

  const baseLayers = {
    Light: <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />,
    Dark: <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />,
    Terrain: <TileLayer url="https://stamen-tiles.a.ssl.fastly.net/terrain/{z}/{x}/{y}.jpg" />, // Replace with the URL for the terrain layer
    Esri_World_Imagery: <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />, // Replace with the URL for Esri World Imagery
    Satellite: <TileLayer url={process.env.REACT_APP_MAPBOX_STYLE_SATELLITE} /> // Replace with the URL for the satellite layer
  };

  const overlayLayers = {
    // Define your overlay layers here
    // Example:
    // 'Markers': <LayerGroup>{/* your markers layer */}</LayerGroup>,
    // 'Polygons': <LayerGroup>{/* your polygons layer */}</LayerGroup>,
    // ...
  };

  const saveArea = React.useCallback((dataTransfer) => {
    var layer = objectRef.current[dataTransfer.dataId].leafletElement;

    var points = dataTransfer.points;
    var arrPoints = [];

    for (var point of points[0]) {
      arrPoints.push(Object.values(point));
    }

    var acreage = (geodesy.area(layer) / 10000).toFixed(2);

    var dataTmp = {
      primary_object: {
        data_area: _.map(arrPoints, (point) => {
          return point.slice(0, 2).toString().replace(',', ', ');
        }),
        acreage
      }
    }

    primaryObjectService.updateArea(dataTmp, dataTransfer.dataId).then(res => {
      if (res.code === '0000') {
        // updatePropertyDetail(activePropertyId);
        layer.editing.disable();
      }
    });
  }, [collapsed]);

  const enableDrawing = React.useCallback((dataTransfer) => {
    if (currentObject) {
      if (currentObject === 'addArea') {
        enableAction('draw', 'polygon');
      } else if (currentObject === 'addTask') {
        enableAction('draw', 'marker');
      } else if (currentObject === 'addMob') {
        enableAction('draw', 'marker');
      } else if (currentObject === 'addAsset') {
        enableAction('draw', 'marker');
      } else if (currentObject === 'addObservation') {
        enableAction('draw', 'marker');
      }
    }
  });

  useEffect(() => {
    L.drawLocal.draw.toolbar.buttons.polygon = 'Create Object (Paddock)';
    L.drawLocal.edit.toolbar.buttons.edit = 'Edit Object (Paddock)';
    L.drawLocal.edit.toolbar.buttons.remove = 'Delete Item';
    L.drawLocal.draw.toolbar.buttons.marker = 'Create Task';
  }, []);

  useEffect(() => {
    if (currentObject) {
      enableDrawing(currentObject);
    }
  }, [currentObject]);

  const cancelSaveArea = React.useCallback((propertyId, dataObjectId) => {
    var layer = objectRef.current[dataObjectId].leafletElement;
    // updatePropertyDetail(propertyId);
    layer.editing.disable();
  }, []);

  useEffect(() => {
    if (activePropertyId) {
      setPropertyId(activePropertyId);
      updatePropertyDetail(activePropertyId);
    }
  }, [activePropertyId, updatePropertyDetail, activePropertySubscription, collapsed]);

  useEffect(() => {
    if (saveSuccess && dataTransfer.type === 'area') {
      saveArea(dataTransfer);
    } else if (cancelSave && dataTransfer.type === 'area') {
      cancelSaveArea(propertyId, dataTransfer.dataId);
    }
  }, [saveSuccess, saveArea, cancelSave, cancelSaveArea, propertyId, dataTransfer,collapsed])

  const onEdited = (editedObject) => {
    const objectTmp = _.map(primaryObjects, (object) => {
      if (object.id === editedObject.id) {
        return editedObject;
      }
      return object;
    })
    setPrimaryObjects(objectTmp)
  }

  const menu = () => (
    <Menu onClick={handleMenuClick.bind(this)} style={{ width: '150px' }}>
      <Menu.Item key="addArea">
        <IntlMessages id="propertyMapPage.area" />
      </Menu.Item>
      <Menu.Item key="addTask">
        <IntlMessages id="propertyMapPage.task" />
      </Menu.Item>
      {/* <Menu.Item key="addMob">
        <IntlMessages id="propertyMapPage.mob" />
      </Menu.Item> */}
      {/* <Menu.Item key="addLiveStock">
        <IntlMessages id="propertyMapPage.livestock" />
      </Menu.Item> */}
      <Menu.Item key="addAsset">
        <IntlMessages id="propertyMapPage.asset" />
      </Menu.Item>
      {/* {
        subscriptionHelper.checkIsGold(activePropertySubscription) && 
          <Menu.Item key="addObservation">
            <IntlMessages id="observation" />
          </Menu.Item>
      } */}
    </Menu>
  );

  const enableAction = (actionType, value) => {
    if (controlDraw.current.leafletElement._toolbars[actionType]._modes[value]) {
      controlDraw.current.leafletElement._toolbars[actionType]._modes[value].handler.enable();
    } else {
      controlDrawMarker.current.leafletElement._toolbars[actionType]._modes[value].handler.enable();
    }
  }

  const handleMenuClick = async (e) => {
    setCurrentObject(e.key);
    if (e.key === 'addArea') {
      await setDisabledArea(true);
      enableAction('draw', 'polygon');
    } else if (e.key === 'addTask') {

      setActionName('addTask')
      enableAction('draw', 'marker');
    } else if (e.key === 'addObservation') {
      setActionName('addObservation')
      enableAction('draw', 'marker');
    }
    else if (e.key === 'addMob') {
      updatePropertyDetail(activePropertyId);
      var layer = null;
      var type = 'mob';
      var point = null;
      var canCreate = false;
      const modalData = {
        featureGroup: editableFG,
        layer,
        type,
        canCreate,
        object: {
          point
        }
      }
      dispatch(openModal(modalData));
    } else if (e.key == "addLiveStock") {
      var type = 'livestock';
      const modalData = {
        type
      }
      dispatch(openModal(modalData));
    } else if (e.key == 'addAsset') {
      updatePropertyDetail(activePropertyId);
      var layer = null;
      var type = 'asset';
      var point = null;
      var canCreate = false;
      const modalData = {
        featureGroup: editableFG,
        layer,
        type,
        canCreate,
        object: {
          point
        }
      }
      dispatch(openModal(modalData));
    }

  }

  const onCreated = async drawControl => {
    var type = drawControl.layerType,
      layer = drawControl.layer;
    setCurrentLayer(layer);
    if (type === 'polygon') {
      handlePolygon(layer);
    } else if (type === 'marker') {
      handleMarker(layer);
    }
    await setDisabledArea(false);
  }

  const handleMarker = (layer) => {
    let type = "";
    let canCreate = true;
    type = actionName === "addObservation" ? 'observation' : 'task';

    var point = layer.getLatLng();
    const modalData = {
      featureGroup: editableFG,
      layer,
      type,
      canCreate,
      object: {
        point
      }
    }
    dispatch(openModal(modalData));
  }

  const handlePolygon = (layer) => {
    var points = layer.getLatLngs();
    var arrPoints = [];

    for (var point of points[0]) {
      arrPoints.push(Object.values(point));
    }

    var acreage = (geodesy.area(layer) / 10000).toFixed(2);

    if (propertyAcreageUnit === 'acres') {
      acreage = (acreage * 2.471).toFixed(2);
    }

    if (propertyAcreageUnit === 'yard') {
      acreage = (acreage * 11959.9005).toFixed(2);
    }
    const modalData = {
      featureGroup: editableFG,
      layer,
      type: 'area',
      object: {
        coordinates: arrPoints,
        acreage
      }
    }
    dispatch(openModal(modalData));
  }

  const onFeatureGroupReady = reactFGref => {
    // store the featureGroup ref for future access to content
    setEditableFG(reactFGref);
  };

  const handleShowPrimaryObjects = (objects, heatMap) => {
    var html = [];
    if (objects.length > 0) {
        _.forEach(objects, function (object) {
            let color;

            if (heatMap) {
                if (object.total_mobs == 0) {
                    color = commonHelper.generateRestColor(object.rest_days);
                } else {
                    color = commonHelper.generateColorInUse(object.in_use_days);
                }
            } else {
                const colorIndex = _.findIndex(objectColors, (color) => {
                    // Convert both color.value and object.color to lowercase for case-insensitive comparison
                    return color.value.toLowerCase() === object.color.toLowerCase();
                });

                color = colorIndex !== -1 ? objectColors[colorIndex].color : '#defaultColor';
                console.log("color:436", color);
            }

            html.push(
                <Polygon key={object.id}
                         positions={object.area}
                         onClick={handleClickObject.bind(this, object)}
                         onRemove={handleRemoveObject.bind(this, object)}
                         onEdit={handleEditObject.bind(this, object)}
                         fillColor={color}
                         color={showHeatMap ? '#000000' : color}
                         className={`primary-objects type_id_${object.type_id}`}
                         ref={el => (objectRef.current[object.id] = el)}
                         fillOpacity={showHeatMap ? 1 : 0.2}
                >
                    <Tooltip permanent={true} direction="center"
                             className={`primary-objects-tooltip ${showHeatMap ? 'dark' : ''} type_id_${object.type_id} ${!isShowAreaLabel ? 'not-show-area-label' : ''}`}>
                        <p>{object.name}</p>
                        <p>{parseFloat(object.acreage).toFixed(1)}<span> {object.acreage_unit}</span></p>
                    </Tooltip>
                </Polygon>
            );
        });
    }
    return html;
}



  const handleClickObject = (object, e) => {
    if (disabledArea == true) {
      return false;
    }
    if (!controlDraw.current.leafletElement._toolbars.edit._modes.remove || !controlDraw.current.leafletElement._toolbars.edit._modes.remove.handler._enabled) {
      if (!btnVisibility) {
        // const modalData = {
        //   object,
        //   type: 'summary',
        //   hideEditArea: !(activePermission == "owner" || activePermission == "admin" || activePermission == "modify")
        // }
        // dispatch(openModal(modalData));
        setDataObject({});
        viewPrimaryObject(object.id);
        setCurrentPos(e.latlng);
        showSummaryModal();
      }
    }
  }
  const viewPrimaryObject = (id) => {
    primaryObjectService.view(id).then(res => {
      if (res.code === '0000') {
        setDataObject(res.primary_object);
      }
    });
  }
  const showSummaryModal = () => {
    setVisibleSummary(true);
  }

  const cancelSummaryModal = () => {
    setVisibleSummary(false);
  }

  const [deletedObjs, setDeletedObjs] = useState([]);
  const handleRemoveObject = (object, e) => {
    deletedObjs.push(object.id);
    setDeletedObjs(deletedObjs);
  }

  const [updatedObjs, setUpdatedObjs] = useState({});
  const handleEditObject = (object, e) => {
    let LatLngs = e.target.getLatLngs()[0];

    let area = _.map(LatLngs, (point) => {
      return [point.lat, point.lng];
    });
    let area2 = _.map(LatLngs, (point) => {
      return point.lat + ", " + point.lng;
    });

    object.area = area;
    updatedObjs[object.id] = area2;
    setUpdatedObjs(updatedObjs);
  }

  const editArea = () => {
    const data = {
      objectRef,
      dataId: modalData.object.id,
      type: 'area'
    }
    dispatch(show(data));
    objectRef.current[modalData.object.id].leafletElement.editing.enable();
  }

  const onMapDeleted = (e) => {
    _.forEach(deletedMobs, function (mobId) {
      mobService.destroy(mobId);
      updatePropertyDetail(activePropertyId)
    });
    setDeletedMobs([]);

    _.forEach(deletedTasks, function (taskId) {
      taskService.destroy(taskId);
    });
    setDeletedTasks([]);

    _.forEach(deletedObjs, function (objectId) {
      primaryObjectService.destroy(objectId);
    });
    setDeletedObjs([]);

    _.forEach(deletedAssets, function (assetId) {
      assetService.destroy(assetId);
    });
    setDeletedMobs([]);
  }

  const [deletedMobs, setDeletedMobs] = useState([]);
  const handleSetDeletedMobs = (mobId) => {
    deletedMobs.push(mobId);
    setDeletedMobs(deletedMobs);
  }

  const [deletedTasks, setDeletedTasks] = useState([]);
  const handleSetDeletedTasks = (taskId) => {
    deletedTasks.push(taskId);
    setDeletedTasks(deletedTasks);
  }

  const [deletedAssets, setDeletedAssets] = useState([]);
  const handleSetDeletedAssets = (assetId) => {
    deletedAssets.push(assetId);
    setDeletedAssets(deletedAssets);
  }

  const messageWarning = () => {
    messageApi.open({
      type: 'warning',
      content: 'The mob area is not enough',
    });
  };

  const getRandomLatLng = (coordinates) => {
    var polygon = L.polygon([
      coordinates
    ]);
    var bounds = polygon.getBounds();
    var x_max = bounds.getEast();
    var x_min = bounds.getWest();
    var y_max = bounds.getSouth();
    var y_min = bounds.getNorth();
    var lat = 0, lng = 0;
    var count = 0;
    var area = polygon.getLatLngs()[0].map(function (point) {
      return [point.lat, point.lng]
    });

    while (!inside([lat, lng], area)) {
      lat = y_min + (Math.random() * (y_max - y_min));
      lng = x_min + (Math.random() * (x_max - x_min));
      count += 1;
      if (count == 100) {
        break;
      }
    }

    return new L.LatLng(
      lat,
      lng
    );
  }

  const onMapEdited = (e) => {

    _.forEach(updatedTasks, function (taskData, taskId) {
      const data = {
        task: taskData
      }
      taskService.storeOrUpdateTasks(data, taskId);
    });

    setUpdatedTasks({});

    // _.forEach(updatedMobs, function(mobData, mobId){
    //   const data = {
    //     mob: mobData
    //   }
    //   mobService.storeOrUpdate(data, mobId);
    // });
    // updatePropertyDetail(activePropertyId);
    // setUpdatedMobs({});

    _.forEach(updatedObjs, function (dataArea, objectId) {
      const data = {
        primary_object: {
          data_area: dataArea
        }
      }
      primaryObjectService.updateArea(data, objectId)
    });

    setUpdatedObjs({});

    _.forEach(updatedAssets, function (facilityData, assetId) {
      const data = {
        facility: facilityData
      }
      assetService.storeOrUpdate(data, assetId)
    });
    setUpdatedAssets({});
  }




  const [updatedTasks, setUpdatedTasks] = useState({});

  const handeSetUpdatedTasks = (task, LatLng) => {

    updatedTasks[task.id] = {
      ...task,
      point: LatLng
    }
    setUpdatedTasks(updatedTasks);
  }

  const [updatedMobs, setUpdatedMobs] = useState({});
  const handeSetUpdatedMobs = (mob, LatLng) => {
    updatedMobs[mob.id] = {
      ...mob,
      point: LatLng
    }

    setUpdatedMobs(updatedMobs);
  }
  const [updatedAssets, setUpdatedAssets] = useState({});
  const handeSetUpdatedAssets = (asset, LatLng) => {
    updatedAssets[asset.id] = {
      ...asset,
      point: LatLng
    }
    setUpdatedAssets(updatedAssets);
  }
  const [showArea, setShowArea] = useState(true);
  const [showTask, setShowTask] = useState(true);
  const [showAsset, setShowAsset] = useState(true);
  const [showMob, setShowMob] = useState(true);
  const [showSatellite, setShowSatellite] = useState(true);
  const [showHeatMap, setShowHeatMap] = useState(false);

  const menuShowOptions = () => (
    <Menu>
      <Menu.Item key="showArea">
        <Checkbox checked={showArea} onChange={() => setShowArea(!showArea)} id='show-area'>
          <IntlMessages id="propertyMapPage.showArea" />
        </Checkbox>

        <SettingOutlined onClick={() => setVisiblePrimaryObjectFilterModal(true)}></SettingOutlined>
      </Menu.Item>
      <Menu.Item key="showAsset" htmlFor="show-asset">
        <Checkbox checked={showAsset} onChange={() => setShowAsset(!showAsset)} id='show-asset'>
          <IntlMessages id="Asset" />
        </Checkbox>
        <SettingOutlined onClick={() => setVisibleAssetFilterModal(true)}></SettingOutlined>
      </Menu.Item>
      <Menu.Item key="showTask" htmlFor="show-task">
        <Checkbox checked={showTask} onChange={() => setShowTask(!showTask)} id='show-task'>
          <IntlMessages id="propertyMapPage.showTask" />
        </Checkbox>

      </Menu.Item>
      {/* <Menu.Item key="showMob" htmlFor="show-mob">
        <Checkbox checked={showMob}  onChange={() => setShowMob(!showMob)}   id='show-mob'>
          <IntlMessages id="propertyMapPage.showMob" />
        </Checkbox>
      </Menu.Item> */}
      { subscriptionHelper.checkIsGold(activePropertySubscription) &&
        <Menu.Item key="showHeatMap" htmlFor="show-heat-map">
          <Checkbox checked={showHeatMap} onChange={() => setShowHeatMap(!showHeatMap)} id='show-heat-map'>
            <IntlMessages id="propertyMapPage.showHeatMap" />
          </Checkbox>
        </Menu.Item>
      }
      {(subscriptionHelper.checkIsSilver(activePropertySubscription) || subscriptionHelper.checkIsGold(activePropertySubscription)
        || ((subscriptionHelper.checkIsBronze(activePropertySubscription) || activePropertySubscription === false) && activePropertyTrialSatelliteExpired === false)) ?
        <Menu.Item key="showSatellite" htmlFor="show-satellite">
          <Checkbox checked={showSatellite} onChange={() => setShowSatellite(!showSatellite)} id='show-satellite'>
            <IntlMessages id="propertyMapPage.showSatellite" />
          </Checkbox>
        </Menu.Item> : ''
      }
      {!showSatellite && <Menu.Item key="darkTheme" htmlFor="dark-theme">
        <Checkbox checked={darkTheme} onChange={() => setDarkTheme(!darkTheme)} id='dark-theme'>
          <IntlMessages id="propertyMapPage.darkTheme" />
        </Checkbox>
      </Menu.Item>
      }
      { subscriptionHelper.checkIsGold(activePropertySubscription) &&
        <Menu.Item key="trackTrace" htmlFor="dark-theme">
          <Checkbox checked={trackTrace} onChange={onChangeTrackTrace} id='track-trace'>
            <IntlMessages id="propertyMapPage.trackTrace" />
          </Checkbox>
        </Menu.Item>
      }
    </Menu>
  );
  const onChangeTrackTrace = (e) => {
    setTrackTrace(e.target.checked);
    if (e.target.checked) {
      getPositionDevice();
    } else {
      updatePropertyDetail(propertyId);
    }

  }
  const menuLayerOptions = () => {
    let options = [];
    _.forEach(listLayer, (object, index) => {
      options.push(
        <Menu.Item className="menu-item-custom" key={"layer-" + index}>
          <Radio value={object.id}>{object.layer_name}</Radio>
        </Menu.Item>
      );
    })
    return (
      <Radio.Group onChange={onchangeLayer} value={layerChosen}>
        <Menu>
          {options}
          {(
            (subscriptionHelper.checkIsSilver(activePropertySubscription) && listLayer.length < 1)
            ||
            subscriptionHelper.checkIsGold(activePropertySubscription))

            &&
            <Menu.Item className="menu-item-custom" key="addLayer" htmlFor="addLayer" onClick={handleAddLayer} style={{ textAlign: "center" }}>
              <PlusOutlined />
            </Menu.Item>
          }
        </Menu>
      </Radio.Group>
    )
  }

  const onchangeLayer = (e) => {
    setLayerChosen(e.target.value);
    setIsEditingLayer(true);
  }
  const handleAddLayer = async () => {
    var type = 'addLayer';
    const modalData = {
      type
    }
    dispatch(openModal(modalData));
  }

  const handleVisibleChange = (flag) => {
    setVisibleShowOption(flag);
  }

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  }

  const browser = detect();

  const changeZoomLevelToDefault = () => {
    setZoomDefault(16);
  }
  const getListLayer = () => {
    layerService.getList(propertyId)
      .then(res => {
        if (res.code === "0000") {
          setListLayer(res.layers);
        }
      })
  }
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (primaryObjects) {
      var boudary = [];
      _.forEach(primaryObjects, (object, index) => {
        boudary.push([object.lat_centroid, object.lng_centroid]);
      })

      if (propertyId) {
        getListLayer();
      }
    }
    if (primaryObjects && primaryObjects.length == 1) {
      setPosition(boudary[0]);
    } else {
      if (boudary.length != 0) {

        map.fitBounds(boudary, { padding: [0, 0] });
      }
    }


  }, [mapRef,collapsed, primaryObjects, propertyId]);


  const getPositionDevice = () => {

    let position = [];
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    iotDevice.positionDevice(propertyId).then(res => {
      if (res.code === '0000') {
        setPositionDevice(res.positions);
        setLoading(false);
        if (res.positions && res.positions.length > 0) {
          _.forEach(res.positions, function (data) {
            if (data.position && data.position.length > 0) {
              _.forEach(data.position, function (p) {
                if (p.latitude && p.longitude) {
                  position.push([p.latitude, p.longitude]);
                }
              });
            }
          });
          let bounds = new L.LatLngBounds(position);
          let center = bounds.getCenter();
          setPosition([center.lat, center.lng]);
          map.fitBounds(bounds, { padding: [30, 30] });
        }
      }
    });
  }
  const listAllMarkerArea = (object) => {
    const polygon = L.polygon([
      object.area
    ]);
    const bounds = polygon.getBounds();

    var y_min = polygon.getBounds().getWest();
    var y_max = polygon.getBounds().getEast();
    var x_min = polygon.getBounds().getSouth();
    var x_max = polygon.getBounds().getNorth();
    const cellSide = 0.05;
    const options = { units: 'kilometers' };
    const extent = [y_min, x_min, y_max, x_max];
    const grid = turf.pointGrid(extent, cellSide, options);

    let positionMarker = [];
    var area = polygon.getLatLngs()[0].map(function (point) {
      return [point.lat, point.lng]
    });
    _.forEach(grid.features, function (point) {
      if (
        inside([point?.geometry?.coordinates[1], point?.geometry?.coordinates[0]], area)
        &&
        point?.geometry?.coordinates[1] < object.lat_centroid
      ) {
        positionMarker.push(
          {
            'lat': point?.geometry?.coordinates[1].toFixed(8),
            'lng': point?.geometry?.coordinates[0].toFixed(8)
          }
        );
      }
    });

    // horizontally sort : lat
    positionMarker.sort(function (a, b) {
      if (a.lat < b.lat) return -1;
      if (a.lat > b.lat) return 1;
      if (a.lat == b.lat) {
        if (a.lat > b.lat) return -1; else return 1;
      }
    });

    // reverse array
    if (x_min > 0 && x_max > 0) {
      positionMarker = [...positionMarker].reverse();
    }

    // arrange middle in array  
    let xHorizontal = positionMarker.filter(x => x.lat == positionMarker[0].lat);

    let xMiddle = xHorizontal[Math.round((xHorizontal.length - 1) / 2)];
    let yAllMiddle = positionMarker.filter(y => y.lng == xMiddle.lng);
    let YMiddle = yAllMiddle[Math.round((yAllMiddle.length - 1) / 2)];
    let xUnderName = positionMarker.filter(x => x.lat == YMiddle.lat);

    let xMarkerSort = [];
    _.forEach(xUnderName, function (pM, i) {
      // get middle item
      xMiddle = xUnderName[Math.round((xUnderName.length - 1) / 2)];

      // get index middle item
      var indexMiddle = _.findIndex(xUnderName, xMiddle);
      if (!_.find(xMarkerSort, xMiddle)) {
        xMarkerSort.push(xMiddle);
        xUnderName.splice(indexMiddle, 1);
      }
    });

    return xMarkerSort;
  }

  const getLatLngMarkerArea = (number, objectPrimary, arrayData) => {
    const points = [];
    const coordinates = listAllMarkerArea(objectPrimary);
    if (arrayData.length > 0) {
      _.forEach(coordinates, function (point) {
        if (!_.find(arrayData, point) && points.length < number) {
          points.push(point);
        }
      });
    } else {
      points.push(coordinates[0]);
    }
    return points;
  }
  const handleFilterPrimaryObjectType = (type) => {

    setSelectedPrimaryObjectTypeIds(type.selectedTypeIds)
    setNotSelectedPrimaryObjectTypeIds(type.notSelectedTypeIds)
  }
  const cancelPrimaryObjectFilterModal = () => {
    setVisiblePrimaryObjectFilterModal(false)
  }
 
  useEffect(() => {
    if (collapsed) {
      // Increment the mapKey to trigger a map reload
      setReRenderMap(Date.now())
      setMapKey(mapKey + 1);

    }
  }, [collapsed]);
  return (
    <>

      <LayoutWrapper className={`map-wrapper ${browser.name}`}>

        <PropertyMapPage
        
          hidden_primary_type_ids={notSelectedPrimaryObjectTypeIds}
          hidden_asset_categories={notSelectedAssetCategories}
          className={`${showArea ? "" : "not-show-area"} ${showTask ? "" : "not-show-task"}   ${showMob ? "" : "not-show-mob"} ${showAsset ? "" : `not-show-asset .custom-icon-marker.asset.asset_category_${selectedAssetCategories}`} ${showHeatMap ? "leaflet-tile-dark" : ""}`}>
          {!isEditingLayer

            ?
            // <div style={{ position: 'relative', Width: '100%', height: '94%' }}>
            <Map
            className ={ `${collapsed ? 'mapcollapsedwidth':''}`}
              key={reRenderMap}
              ref={mapRef} center={position[0] != null ? position : false} zoom={zoomDefault} zoomControl={false} >
              {((subscriptionHelper.checkIsSilver(activePropertySubscription) || subscriptionHelper.checkIsGold(activePropertySubscription)
                || ((subscriptionHelper.checkIsBronze(activePropertySubscription) || activePropertySubscription === false) && activePropertyTrialSatelliteExpired === false))
                && showSatellite) ?
                <ReactLeafletGoogleLayer type={'hybrid'} useGoogMapsLoader={false} className='leafletMapPane' />
                :
                <TileLayer
               
                  url={darkTheme ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                  attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
              }
              <ZoomControl position="bottomleft" zoomInText="+" zoomOutText="-"  />
              <FeatureGroup
                ref={featureGroupRef => {
                  onFeatureGroupReady(featureGroupRef);
                }}
              >
                <Control position="topleft">
                  <div className="leftComponent">
                    <IntlProvider
                      locale={currentAppLocale.locale}
                      messages={currentAppLocale.messages}
                    >
                      <Dropdown overlay={menuShowOptions} trigger={['click']} open={visibleShowOption} onOpenChange={handleVisibleChange}>
                        <Button className="action-button">
                          <IntlMessages id="propertyMap.title.view" /> <DownOutlined />
                        </Button>
                      </Dropdown>

                    </IntlProvider>
                  </div>
                </Control>

                {/* Start section layer */}
                {(subscriptionHelper.checkIsGold(activePropertySubscription) ||
                  subscriptionHelper.checkIsSilver(activePropertySubscription))
                  &&



                  <Control position="topright">
                    <div className="rightComponent">
                      <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}
                      >
                        <Dropdown overlay={menuLayerOptions} trigger={['click']} >
                          <Button className="action-button">
                            Layer <DownOutlined />
                          </Button>
                        </Dropdown>

                      </IntlProvider>
                    </div>
                  </Control>
                }
                {showHeatMap &&
                  <Control position="topright">
                    <div className="rightComponent">
                      <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}
                      >
                        <HeatBar />
                      </IntlProvider>
                    </div>
                  </Control>
                }
                {/* End section layer */}

                {(activePermission == "owner" || activePermission == "admin" && subscriptionHelper.checkIsNotGold(activePropertySubscription)) &&
                  <Control position="topright">
                    <Provider store={store}>
                      <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}
                      >
                        <div className="leftComponent">
                          <Dropdown overlay={menu()} trigger={['click']}>
                            <Button icon={<PlusOutlined />} type="primary" className="btn-success">
                              <IntlMessages id="propertyMapPage.add" />
                            </Button>
                          </Dropdown>

                          {
                            btnVisibility &&
                            <BtnSaveGroup />
                          }
                        </div>

                      </IntlProvider>
                    </Provider>
                  </Control>
                }
                {(subscriptionHelper.checkIsGold(activePropertySubscription) ||
                  subscriptionHelper.checkIsSilver(activePropertySubscription))
                  &&
                  <>
                    <LayersControl position="topright">
                      {Object.keys(baseLayers).map((key) => (
                        <BaseLayer key={key} name={key}>
                          {baseLayers[key]}
                        </BaseLayer>
                      ))}
                      {Object.keys(overlayLayers).map((key) => (
                        <Overlay key={key} name={key}>
                          {overlayLayers[key]}
                        </Overlay>
                      ))}
                    </LayersControl></>}

                <EditControl
                  position='topleft'
                  onCreated={onCreated}
                  onEdited={onMapEdited}
                  onDeleted={onMapDeleted}
                  draw={{
                    marker: false,
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    rectangle: false,
                    // polygon:false,
                    polygon: ((activePermission === "owner" || activePermission === "admin") &&
                      subscriptionHelper.checkIsNotGold(activePropertySubscription) &&
                      subscriptionHelper.checkIsNotSilver(activePropertySubscription)) ? {
                      allowIntersection: false,
                      showArea: true, // This condition is always true when activePermission is "owner" or "admin"
                    } : false,
                  }}
                  edit={{
                    remove: ((activePermission === "owner" || activePermission === "admin") &&
                      subscriptionHelper.checkIsNotGold(activePropertySubscription) &&
                      subscriptionHelper.checkIsNotSilver(activePropertySubscription)),
                    edit: (activePermission == "owner" || activePermission == "admin" || activePermission == "modify"),
                  }}
                  ref={controlDraw}
                  key={uuidv4()}
                />
                <EditControl
                  position='topleft'
                  draw={{
                    marker: ((activePermission === "owner" || activePermission === "admin") &&
                      subscriptionHelper.checkIsNotGold(activePropertySubscription) &&
                      subscriptionHelper.checkIsNotSilver(activePropertySubscription)),
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    rectangle: false,
                    polygon: false
                  }}
                  edit={{
                    remove: false,
                    edit: false,
                  }}
                  ref={controlDrawMarker}
                  key={uuidv4()}
                />
                {handleShowPrimaryObjects(primaryObjects, showHeatMap)}
                <Task
                  propertyId={propertyId}
                  currentLayer={currentLayer}
                  primaryObjects={primaryObjects}
                  controlDraw={controlDraw}
                  updatePropertyDetail={updatePropertyDetail}
                  handleSetDeletedTasks={handleSetDeletedTasks}
                  handeSetUpdatedTasks={handeSetUpdatedTasks}
                  dataObject={dataObject}
                />

                <Observation
                  propertyId={propertyId}
                  primaryObjects={primaryObjects}
                  dataObject={dataObject}
                  controlDraw={controlDraw}
                  updatePropertyDetail={updatePropertyDetail}
                />

                <Mob
                  propertyId={propertyId}
                  currentLayer={currentLayer}
                  controlDraw={controlDraw}
                  handleSetDeletedMobs={handleSetDeletedMobs}
                  primaryObjects={primaryObjects}
                  updatePropertyDetail={updatePropertyDetail}
                  handeSetUpdatedMobs={handeSetUpdatedMobs}
                  getLatLngMarkerArea={getLatLngMarkerArea}
                />

                <Asset
                  propertyId={propertyId}
                  controlDraw={controlDraw}
                  currentPos={currentPos}
                  handleSetDeletedAssets={handleSetDeletedAssets}
                  primaryObjects={primaryObjects}
                  updatePropertyDetail={updatePropertyDetail}
                  handeSetUpdatedAssets={handeSetUpdatedAssets}
                />
                {trackTrace &&
                  <DeviceMapMarker
                    propertyId={propertyId}
                    getPositionDevice={getPositionDevice}
                    setLoading={setLoading}
                    loading={loading}
                    setShowMarkerAlert={setShowMarkerAlert}
                    positionDevice={positionDevice} />
                }
              </FeatureGroup>

              <PrimaryObjectModal
              propertyId={propertyId}
              updatePropertyDetail={updatePropertyDetail}
              editArea={editArea}
              onEdited={onEdited}
              propertyAcreageUnit={propertyAcreageUnit}
          />
            </Map>
            // </div>
            :
            <DrawTool
              center={position[0] != null ? position : false}
              zoom={zoomDefault}
              darkTheme={darkTheme}
              primaryObject={primaryObjects}
              layerId={layerChosen}
              setIsEditingLayer={setIsEditingLayer}
              propertyId={propertyId}
              getListLayer={getListLayer}
              setLayerChosen={setLayerChosen}

            />
          }
          <NewLiveStockModal propertyId={propertyId} updatePropertyDetail={updatePropertyDetail} propertyPIC={propertyPIC} />
          <AddLayerModal
            propertyId={propertyId}
            listLayer={listLayer}
            setListLayer={setListLayer}
          />
        </PropertyMapPage>
        <SelectPropertyModal visibleSelectProperty={visibleSelectProperty} cancelSelectPropertyModal={cancelSelectPropertyModal} />

        <AreaSummaryModal
          propertyId={propertyId}
          visibleSummary={visibleSummary}
          cancelSummaryModal={cancelSummaryModal}
          dataObject={dataObject}
          updatePropertyDetail={updatePropertyDetail}
          primaryObjects={primaryObjects}
          currentPos={currentPos}
          onEdited={onEdited}
          propertyAcreageUnit={propertyAcreageUnit}
        />

        <PrimaryObjectFilterModal
          visiblePrimaryObjectFilterModal={visiblePrimaryObjectFilterModal}
          cancelPrimaryObjectFilterModal={cancelPrimaryObjectFilterModal}
          propertyId={propertyId}
          handleFilterPrimaryObjectType={handleFilterPrimaryObjectType}
          selectedPrimaryObjectTypeIds={selectedPrimaryObjectTypeIds}
          isShowAreaLabel={isShowAreaLabel}
          setIsShowAreaLabel={setIsShowAreaLabel}
        />

        <AssetFilterModal
          visibleAssetFilterModal={visibleAssetFilterModal}
          selectedAssetCategories={selectedAssetCategories}
          setSelectedAssetCategories={setSelectedAssetCategories}
          cancelAssetFilterModal={cancelAssetFilterModal}
        />
      </LayoutWrapper>
    </>
  );
}

export default PropertyMap;
