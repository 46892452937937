import React, { useState, useEffect } from "react";
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import PageHeader from "@iso/components/utility/pageHeader";
import Box from "@iso/components/utility/box";
import PropertyAreaPage from "./PropertyArea.styles";
import KmzModal from "./KmzModal";
import IntlMessages from "@iso/components/utility/intlMessages";
import {
Button,
Menu,
Dropdown,
Pagination,
message,
Popover,
Popconfirm,
Input,
} from "antd";
import {
  DeleteOutlined,
PlusOutlined,
EditOutlined,
DownOutlined,
SearchOutlined,
CloseCircleOutlined,
PaperClipOutlined,
EyeOutlined,
PlusCircleOutlined,
DownloadOutlined,
UploadOutlined,
EllipsisOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import modalActions from "@iso/redux/modal/actions";
import PrimaryObjectModal from "@iso/containers/Pages/PropertyMap/components/PrimaryObject/PrimaryObjectModal";
import {
propertyService,
permissionService,
primaryObjectService,
activityService,
kmzService,
} from "@iso/services";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import moment from "moment";
import Table from "@iso/components/collapsing-table/table";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SelectPropertyModal from "@iso/containers/Pages/Property/SelectPropertyModal";
import AreaSummaryModal from "@iso/containers/Pages/PropertyMap/components/PrimaryObject/AreaSummaryModal";
import AddAreaHistoryModal from "@iso/containers/Pages/PropertyMap/components/PrimaryObject/AddAreaHistoryModal";
import { CSVLink, CSVDownload } from "react-csv";
import Loader from "../../../components/collapsing-table/components/Loader";
import { subscriptionHelper } from "@iso/lib/helpers/subscription";

const { openModal } = modalActions;

const PropertyArea = (props) => {
  const [primaryObjects, setPrimaryObjects] = useState([]);
  const dispatch = useDispatch();

  const activePropertyId = useSelector(
    (state) => state.property.activePropertyId
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
  const activeProperty = localStorage.getItem("active_property");
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const [visibleSelectProperty, setVisiblitySelectProperty] =
    React.useState(false);

  const [visibleSummary, setVisibleSummary] = useState(false);
  const [dataObject, setDataObject] = useState({});
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [visibleKmz, setVisibleKmz] = useState(false);
  const [objectsForExport, setObjectsForExport] = useState([]);
  const [propertyAcreageUnit, setPropertyAcreageUnit] = useState("");
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loadingData, setLoadingData] = useState(true);
  const [dataObjectPaging, setDataObjectPaging] = useState([]);

  const [searchQuery, setSearchQuery] = React.useState();
  const [searchInput, setsearchInput] = React.useState('');

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const displayedproperties = searchQuery?.slice(startIndex, endIndex);

  const updatePropertyDetail = () => {
    if (activePropertyId) {
      propertyService.viewProperty(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setPrimaryObjects(res.primary_objects);
          setSearchQuery(res.primary_objects);
        }
      });
    }
  };

  useEffect(() => {
    updatePropertyDetail();
    updatePrimaryObjectData();
  }, [activePropertyId]);

  useEffect(() => {
    getDataPaging();
  }, [activePropertyId, page, pageSize]);

  useEffect(() => {
    if (!activeProperty) {
      setVisiblitySelectProperty(true);
    }
  }, []);

  useEffect(() => {
    propertyService.getProperties().then((res) => {
      if (res.properties.length === 0) {
        setVisiblitySelectProperty(true);
      }
    });
  }, [activePropertyId]);

  const getDataPaging = () => {
    if (activePropertyId) {
      
      primaryObjectService
        .getListPaging(activePropertyId, page, pageSize)
        .then((res) => {
          if (res.code === "0000") {
            setTotalItem(res.total_item);
            setTotalPage(res.total_page);
            setCurrentPage(+res.current_page);
        
            setDataObjectPaging(res.object_paging);
              setLoadingData(false);
          }
        });
    }
  };
  const onChangePage = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleExportKmz = () => {
    if (activePropertyId) {
      kmzService.exportKmz(activePropertyId).then((res) => {
        if (res.code === "0000") {
          window.location.href =
            process.env.REACT_APP_API_BASE_URL + res.kmz_url;
        } else {
          message.warning("can't export file because there is no data", 2);
        }
      });
    }
  };

  const updatePrimaryObjectData = () => {
    if (activePropertyId) {
      primaryObjectService.getList(activePropertyId).then((res) => {
        if (res.code === "0000") {
          setPrimaryObjects(res.objects);
          setObjectsForExport(res.objects_for_export);
          setPropertyAcreageUnit(res.property.acreage_unit);
        }
      });
    }
  };

  const showEditAreaModal = (primary) => {
    const modalData = {
      object: primary,
      type: "area",
      hideEditArea: true,
    };
    dispatch(openModal(modalData));
  };

  const history = useHistory();
  const showModal = () => {
    history.push("/property-map");
  };

  const cancelSelectPropertyModal = () => {
    setVisiblitySelectProperty(false);
  };

  const menu = (primary) => (
    <Menu onClick={handleMenuClick.bind(this, primary)}>
      <Menu.Item key="editDetails" icon={<EditOutlined />}>
        <IntlMessages id="propertyPage.managePage.editDetails" />
      </Menu.Item>
      {(activePermission == "owner" ||
        activePermission == "admin" ||
        activePermission == "modify") && (
          <Menu.Item key="addHistory" icon={<PlusCircleOutlined />}>
            <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
          </Menu.Item>
        )}
      <Menu.Item key="viewSummary" icon={<EyeOutlined />}>
        <IntlMessages id="propertyPage.modal.viewSummary" />
      </Menu.Item>
      <p style={{ cursor: "pointer" }} className="deleteButton">
           <Popconfirm
             placement="bottomRight"
             title="You are about to delete individual record"
             onConfirm={() => {
               confirmDelete(primary);
             }}
             okText="Yes"
             cancelText="No"
             className="delete-button"
           >
             <a style={{ color: "rgba(0, 0, 0, 0.65)", marginLeft: "11px" }}>
               {<DeleteOutlined />}&nbsp;&nbsp;
               <IntlMessages id="propertyPage.managePage.delete" />
             </a>
           </Popconfirm>
         </p>
    </Menu>
  );

  const confirmDelete = (primary) => {
  
    if (primary.id) {

      primaryObjectService.destroy(primary.id).then((res) => {
        if (res.code === "0000") {
          updatePropertyDetail();
    updatePrimaryObjectData();
    getDataPaging();
        }
      });
    }
  };

  const handleMenuClick = (primary, e) => {
    if (e.key === "editDetails") {
      showEditAreaModal(primary);
    }

    if (e.key === "addHistory") {
      setDataObject(primary);
      setVisibleHistory(true);
    }

    if (e.key === "viewSummary") {
      setDataObject(primary);
      showSummaryModal();
    }
  };

  const columns = [
    {
      label: <IntlMessages id="propertyAreaPage.table.name" />,
      accessor: 'name',
      minWidth: 100,
      sortable: true,
      position: 1,
      priorityLevel: 1,
      CustomComponent: (cell) => {
        let area = cell.row;
        const hasAttachment =area && area.images; // Replace with your attachment presence condition
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
          
            <div style={{ marginLeft: hasAttachment ? 10 : 0 }}>{area.name}</div>
            {hasAttachment.length && hasAttachment.length >0 && <PaperClipOutlined style={{color:'rgb(248, 14, 70)'}}/>} {/* Add the PaperclipIcon conditionally */}
          </div>
        );
      },
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.type" />,
      accessor: "type",
      minWidth: 100,
      sortable: true,
      position: 2,
      priorityLevel: 2,
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.cropType" />,
      accessor: "crop_label",
      minWidth: 100,
      sortable: true,
      position: 3,
      priorityLevel: 3,
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.areaColour" />,
      accessor: "color_label",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      CustomComponent: (cell) => {
        let primary = cell.row;
        return objectColors.map((value, index) => {
          const opacity = 0.30;
          const rgbValues = value.color.match(/\d+/g);
          const colorWithOpacity = `rgba(${rgbValues[0]}, ${rgbValues[1]}, ${rgbValues[2]}, ${opacity})`;

          if (value.value.toLowerCase() == primary.color.toLowerCase()) {
            return (
              <div className="" style={{ display: "flex", backgroundColor: colorWithOpacity, padding: "5px 10px", borderRadius: '10px', gap: "6px", maxWidth: "130px", alignItems: "center" }}>
                <ColorItemPreview
                  style={{ backgroundColor: value.color }}
                ></ColorItemPreview>
                &nbsp;&nbsp;
                <div>{value.label}</div>
              </div>
            );
          }
        });
      },
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.acerage" />,
      accessor: "acreage",
      minWidth: 200,
      sortable: true,
      position: 5,
      priorityLevel: 5,
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.restDays" />,
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let primary = cell.row
        return primary.total_mobs == 0 ? '+ ' + primary.rest_days : '- ' + primary.in_use_days;
      }
    },
    {
      label: <IntlMessages id="propertyAreaPage.table.lastUpdated" />,
      accessor: 'updated_at',
      minWidth: 200,
      sortable: true,
      position: 6,
      priorityLevel: 6,
      CustomComponent: cell => {
        let primary = cell.row
        return moment(primary.updated_at).format('DD/MM/YYYY');
      }
    },
    {
      label: "",
      accessor: "dot-menu",
      minWidth: 100,
      sortable: true,
      position: 4,
      priorityLevel: 4,
      noTitle: true,
      CustomComponent: (cell) => {
        let primary = cell.row;
        return (
          <Dropdown overlay={menu(primary)} trigger={["click"]}>
            <div
              className="property-area-dot-btn"
              style={{ cursor: "pointer" }}
            >
              <EllipsisOutlined className="dotIcon"/>
            </div>
          </Dropdown>
        );
      },
    },
  ];
  const onEdited = (editedObject) => {
    const objectTmp = _.map(primaryObjects, (object) => {
      if (object.id === editedObject.id) {
        return editedObject;
      }
      return object;
    });
    setPrimaryObjects(objectTmp);
    getDataPaging();
   
  };

  const showSummaryModal = () => {
    setVisibleSummary(true);
  };

  const cancelSummaryModal = () => {
    setVisibleSummary(false);
  };

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  };
  const showModalKmz = () => {
    setVisibleKmz(true);
  };
  const cancelModalKmz = () => {
    setVisibleKmz(false);
  };

  useEffect(() => {
    const propertyAreaLength = dataObjectPaging.length
    if(props?.handlePropertyArea){
      props?.handlePropertyArea(propertyAreaLength);
    }
  }, [dataObjectPaging, props.handlePropertyArea]);

  

  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1); 
    setPageSize(size);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setsearchInput(e.target.value);
    
    // Filter the employee data based on the search query
    if(searchInput){
    const filteredEmployee = primaryObjects.filter((employee) => {
      return (
        employee.name && employee.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.address && employee.address.toLowerCase().includes(searchInput.toLowerCase()) ||
        employee.subscription_label && employee.subscription_label.toLowerCase().includes(searchInput.toLowerCase())
        
      )
    });
    setSearchQuery(filteredEmployee);
  }else{
    setSearchQuery(primaryObjects);
  }
  };

  const handleCancelSearch = () => {
    // Clear the search input and reset the employee data
    setsearchInput('');
    setSearchQuery(primaryObjects);
    setDataObjectPaging(primaryObjects);
    // getEmployee();
  };
  return (
    <LayoutWrapper>
      <PropertyAreaPage>
        <Box id="containerBox">
          <div className="area_top">
            <div className="mp-title">
              <h2 className="area-property-title">Property Area</h2>
              <Input
                placeholder="Search…"
                suffix={!searchInput && <SearchOutlined className="site-form-item-icon" />}
                prefix={searchInput && <CloseCircleOutlined onClick={handleCancelSearch} className="site-form-item-cancel" />}
                size="large"
                className="search-bar"
                value={searchInput}
                onChange={(e) => handleSearch(e)}
               
              />
            </div>
            <div className="button-group">
              { subscriptionHelper.checkIsGold(activePropertySubscription) && (
              <>
                <Button
                  type="primary"
                  className="propertyarea-import-btn"
                  onClick={showModalKmz}
                >
                  <UploadOutlined /> Import File KMZ
                </Button>
                &nbsp;
                <Button
                  type="primary"
                  className=" propertyarea-export-btn"
                  onClick={handleExportKmz}
                >
                  <DownloadOutlined />
                  Export File KMZ
                </Button>&nbsp;
                <CSVLink filename={"area_list_" + moment().format('DD/MM/YYYY HH:mm:ss') + ".csv"} data={objectsForExport}>
                <Button type="primary" className="btn-success propertyarea-import-btn"><DownloadOutlined /><IntlMessages id="Download CSV" /></Button>
              </CSVLink>
              </>
              )} 
              &nbsp;
              {(activePermission == "owner" || activePermission == "admin") && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  className="property-area-add-btn"
                  onClick={showModal}
                >
                  <IntlMessages id="propertyAreaPage.managePage.addPropertyArea" />
                </Button>
              )}
            </div>
          </div>
          {displayedproperties?.length > 0 ? (
          <>
              {loadingData ? (
                <Loader/>
              ) : (
                
                <div className="area-manage-table">
                  <Table
                    columns={columns}
                    rows={displayedproperties}
                    containerId="containerBox"
                    showPagination={false}
                    rowSize={99999}
                  />
               
                <div style={{margin:'20px'}}>
                <Pagination
                  current={currentPage}
                  total={searchQuery?.length}
                  pageSize={pageSize}
                  onChange={handlePageChange}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "50", "100"]}
                  onShowSizeChange={handlePageSizeChange}
                /></div>
             </div>
              )}
            </>
          ) : (
            <h1
              style={{
                paddingLeft: "20px",
                paddingBottom: "20px",
                fontSize: "14px",
                fontWeight: "600"
              }}
            >
              <IntlMessages id="dashboardPage.noData" />
            </h1>
          )}
          {/* {totalPage > 1 && (
            <div style={{ margin: "20px" }}>
              <Pagination
                total={totalItem}
                pageSize={pageSize}
                showSizeChanger
                current={currentPage}
                onChange={onChangePage}
                onShowSizeChange={onShowSizeChange}
              />
            </div>
          )} */}
        </Box>
      </PropertyAreaPage>
      <PrimaryObjectModal
        propertyId={activePropertyId}
        updatePropertyDetail={updatePropertyDetail}
        onEdited={onEdited}
        getDataPaging={getDataPaging}
        primaryObjects={primaryObjects}
        edit={true}
        propertyAcreageUnit={propertyAcreageUnit}
      />

      {/* <SelectPropertyModal visibleSelectProperty={visibleSelectProperty} cancelSelectPropertyModal={cancelSelectPropertyModal}/> */}
      <AreaSummaryModal
        propertyId={activePropertyId}
        visibleSummary={visibleSummary}
        cancelSummaryModal={cancelSummaryModal}
        dataObject={dataObject}
        updatePropertyDetail={updatePropertyDetail}
        primaryObjects={primaryObjects}
        propertyArea={true}
        onEdited={onEdited}
        propertyAcreageUnit={propertyAcreageUnit}
      />

      <AddAreaHistoryModal
        visibleHistory={visibleHistory}
        model="areas"
        type="area"
        primaryObjects={primaryObjects}
        cancelModal={cancelModalHistory}
        propertyId={activePropertyId}
        dataId={dataObject.id}
        updatePrimaryObjectData = {updatePrimaryObjectData}
        updateAreaHistoryData={updatePropertyDetail}
        getDataPaging  ={getDataPaging}
      />
      <KmzModal
        cancelModalKmz={cancelModalKmz}
        visibleKmz={visibleKmz}
        setVisibleKmz={setVisibleKmz}
        primaryObjects={primaryObjects}
        updatePrimaryObjectData={updatePrimaryObjectData}
        getDataPaging={getDataPaging}
        propertyId={activePropertyId}
      />
    </LayoutWrapper>
  );
};

export default PropertyArea;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
